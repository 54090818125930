import http from './http.js'

// 获取验证码，这个函数指的是使用GET请求请求目标服务器的

export function getlotteryproduct(data) {
    return http.post("kaola-cash/lotteryproduct/getlist?pageNumber=1&pageSize=100",data)
}

export function start(data) {
    return http.get("kaola-cash/lottery/start?storeId="+data.storeId+"&mobile="+data.mobile)
}
export function makesure(id) {
    return http.get("kaola-cash/lottery/makesure?lotteryid="+id)
}
export function lotterylist(data) {
    return http.post("kaola-cash/lottery/getlist?pageNumber="+data.pageNumber+"&pageSize=100",data);
}

<template>
    <div >
        <div class="myrecord">我的中奖记录</div>
        <van-list
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
        >
           <!-- <van-cell-group inset>
            <van-cell style="margin-top: 20px" center v-for="item  in list" :key="item.id" :title="item.productId>0?item.productName:'谢谢参与'" :value="item.createTime" />
            </van-cell-group>-->
         <div v-for="item  in list" :key="item.id" style="margin-top: 5px;line-height: 20px;width: 100%;margin-left: 20px">
                <span v-if="item.productId>0" style="width: 60%"> 喜中{{item.productName}}</span>
                <span v-if="item.productId<0"> 谢谢参与</span>
                <span style="margin-left: 50px;width: 30%">{{formatTimer(item.createTime)}}</span>
            </div>
        </van-list>
    </div>
</template>

<script setup>
    import router from "@/router";
    import {getlotteryproduct,start as gotostart,makesure} from "@/api/api.js"
    import { ref, watchEffect, defineProps, defineEmits,onMounted } from 'vue'
    import {List,Cell,CellGroup} from "vant";
    import {lotterylist} from "../api/api";
    import { useRoute } from 'vue-router'
    const list = ref([]);
    const mobile = useRoute().params.mobile
    const loading = ref(false);
    const gotoloading = ref(false);
    const finished = ref(false);
    const pageNumber=ref(1);
    const formatTimer= (value) =>{
            let date = new Date(value);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate();
            d = d < 10 ? "0" + d : d;
            let h = date.getHours();
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes();
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds();
            s = s < 10 ? "0" + s : s;
            return y + "-" + MM + "-" + d + " " + h + ":" + m;
        }

    const onLoad =async () => {
        if (!gotoloading.value){
            gotoloading.value=true;
            const res=await lotterylist({pageNumber:pageNumber.value,pageSize:100,mobile:mobile});
            if (res.code=='000000'){
                if (res.data.records!=undefined&&res.data.records.length>0){
                    for (let i = 0; i < res.data.records.length; i++) {
                        list.value.push(res.data.records[i]);
                    }
                    loading.value = false;
                    gotoloading.value=false;
                }else{
                    finished.value = true;
                }
                pageNumber.value=pageNumber.value+1;
            }

        }
        console.log(list.value)
        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
       /* setTimeout(() => {
            for (let i = 0; i < 10; i++) {
                list.value.push(list.value.length + 1);
            }

            // 加载状态结束


            // 数据全部加载完成
            if (list.value.length >= 40) {
                finished.value = true;
            }
        }, 1000);*/
    };
</script>

<style scoped>
    .myrecord{
        margin-top: 13px;
        display:flex;
        justify-content: center;
        align-items:center;
        color:black;
    }
</style>
